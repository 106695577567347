/**********************
 * Load CSS
 **********************/
import 'bootstrap/dist/css/bootstrap-grid.css'

/*** Load Custom Styles  */
import './sass/main.sass';

/**********************
 * Load JS
 **********************/
import 'jquery';
import 'jquery-scrollify/jquery.scrollify.js';
import './js/components.js';
