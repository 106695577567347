$(document).ready(function () {
  //Change background header on scroll
  if ($('body#front-page').length > 0) {
    let homeSectionHeight = parseInt($('#front-page section.logos').offset().top)-100;
    $(window).on('scroll', function () {
      if ($('.header-nav').hasClass('is-close')) {
        if ($(window).scrollTop() > homeSectionHeight) {
          $('#front-page header.header').css({
            'background-color':'#E8E7E5',
            'box-shadow':'0px 4px 20px rgba(0, 0, 0, 0.15)',
          });
        } else {
          $('#front-page header.header').css({
            'background-color':'transparent',
            'box-shadow':'none'
          });
        }
      }
    });
  } else {
    $(window).on('scroll', function () {
      if ($(window).scrollTop() > 50) {
        $('header.header-page').css({
          'background-color':'#E8E7E5',
          'box-shadow':'0px 4px 20px rgba(0, 0, 0, 0.15)',
        });
      } else {
        $('header.header-page').css({
          'background-color':'transparent',
          'box-shadow':'none'
        });
      }
    });
  }


  //Scrolling
  if ($('body#front-page').length > 0) {
    $("nav.nav").on("click", "a", function (event) {
      event.preventDefault();

      let id = $(this).attr('href'),

        top = $(id).offset().top;

      $('body, html').animate({scrollTop: top}, 1500);

      if ($(window).width() <= 992) {
        $('.header-nav').addClass('is-close').css({
          'transform':'translateX(100%)',
          'transition':'.5s',
        });
      }

    });
  }

  //Menu mobile
  $('.header-burger, .header-close').on('click', function () {
    if ($(window).width() <= 992) {
      if ($('.header-nav').hasClass('is-close')) {
        $('.header-nav').removeClass('is-close').css({
          'transform':'translateX(0)',
          'transition':'.5s',
        });
        $('.header.header').css('background', '#E8E7E5');
        $('.header-burger').addClass('cross').find('.burger-item:last-child').css('display', 'none');
        $('body').css('overflow-y', 'hidden');
      } else {
        $('.header-nav').addClass('is-close').css({
          'transform':'translateX(100%)',
          'transition':'.5s',
        });
        if ($('body#front-page').length > 0) {
          if ($(window).scrollTop() <= $('#front-page section.logos').offset().top) {
            $('header.header').css('background', 'transparent')
          }
        }
        $('.header-burger').removeClass('cross').find('.burger-item:last-child').css('display', 'block');
        $('body').css('overflow-y', 'auto');
      }
    }
  });

});